import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_ROOT_API

class DealerService {
  async getDealerRecent() {
    const response = await axios.get(API_URL + '/resellers/getdealer', { headers: authHeader() })
    return response.data
  }
}

export default new DealerService()
