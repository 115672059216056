<template>
  <div class="data">
    <h1>ตัวแทนของคุณ</h1>
    <form action="#">
      <div class="row md-3">
        <div class="col-sm-6">
          <input type="text" class="form-control" name="search_value" id="search_value" placeholder="ค้นหาจากผู้ขาย หมายเลขออเดอร์" />
        </div>
        <div class="col-sm-3">
          <input type="submit" class="btn btn-secondary" value="ค้นหา" />
        </div>
      </div>
      <br />
    </form>
    <div v-if="dataReady>0">
      <h5>จำนวน 1 คน</h5>
      <form action="/dealerstore">
        <div class="form-data">
          <div id="logo_name">
            <div>
              <img class="col-sm-10" src="../assets/img/profile/Logo.png" />
            </div>
            <div>
              <label for="dealer_name" class="col-sm-8"> <h5>{{dealerData.dealer_info.name}}</h5> </label>
              <div id="status">กำลังใช้งานอยู่</div>
            </div>
          </div>
          <div id="grid">
            <div class="data-detail">
              <div class="col-sm-8">
                <label for="seller" class="col-sm-12 col-form-label head">สั่งซื้อล่าสุด</label>
              </div>
              <div class="col-sm-8">
                <label for="seller" class="col-sm-12 col-form-label data">{{dealerData.created_at.substring(10, 0)}}</label>
              </div>
            </div>
            <div class="data-detail">
              <div class="col-sm-8">
                <label for="seller" class="col-sm-12 col-form-label head">คะแนนของคุณ</label>
              </div>
              <div class="col-sm-8">
                <label for="seller" class="col-sm-12 col-form-label data">{{dealerData.user_info.point}} คะแนน</label>
              </div>
            </div>
          </div>
          <div class="col-sm-12 status">
            <label for="status">สถานะ : </label>
            <label for="status" class="status-detail">เปิดให้บริการ</label>
          </div>

          <button class="btn">ไปหน้าร้าน</button>
        </div>
      </form>
    </div>
    <div v-else>
        <h2>ยังไม่มีข้อมูลตัวแทน</h2>
    </div>
  </div>
</template>

<script>
import DealerService from '../services/dealer.service'
export default {
  name: 'Dealer',
  data() {
    return {
      dealerData: {},
      dataReady: 0
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    }
  },
  async created() {
      const responses = await DealerService.getDealerRecent()
      this.dealerData = responses.data[0]
      this.dataReady = 1
      console.log(responses.data[0])
    
  }
}
</script>

<style scoped>
.data h1 {
  padding: 1%;
  font-size: 30px;
  color: rgb(107, 107, 107);
  text-align: left;
}
.data h5 {
  padding: 1%;
  color: rgb(107, 107, 107);
  text-align: left;
}
.data button {
  width: 350px;
  border-radius: 35px;
  margin-top: 2rem;
  background-color: #63c289;
  color: #fff;
}
.form-data {
  max-width: 450px;
  background: whitesmoke;
  border-radius: 35px;
  box-sizing: border-box;
  box-shadow: 1px 2px 1px 2px lightgray;
  padding: 55px;
}
#logo_name {
  display: grid;
  grid-template-columns: 30% 70%;
}
#status {
  color: #63c289;
}
#grid {
  display: grid;
  grid-template-columns: auto auto;
}
.head,
.status-detail {
  margin-top: 10px;
  color: #63c289;
}
.status {
  text-align: center;
}
.data {
  color: black;
}
</style>
